@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f40076;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
}

* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.rec.rec-arrow {
  background-color: transparent;
  border-radius: none;
  opacity: 1;
  box-shadow: none;
}

.rec.rec-arrow:hover {
  border-radius: none;
  opacity: 1;
  box-shadow: none;
  background-color: transparent;
}

.rec.rec-dot {
  background: #f7ecf1;
  box-shadow: 0;
}

.rec.rec-dot_active {
  background: #f40076;
  border: 1px solid white;
}

button.rec-dot {
  background-color: rgb(186, 186, 186);
  box-shadow: 0 0 1px 3px rgba(255, 255, 255, 0.5);
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
  box-shadow: 0 0 1px 3px rgba(255, 99, 213, 0.5);
}

button.rec.rec-arrow:hover,
button.rec.rec-arrow:active,
button.rec.rec-arrow:focus {
  /* box-shadow: 0 0 1px 3px rgba(255, 99, 213, 0.5); */
  background-color: #f40076;
}

@layer utilities {
  .clip-review {
    /* clip-path: polygon(0% 11%, 100% 8%, 93% 78%, 16% 76%); */
    /* clip-path: polygon(7% 15%, 100% 15%, 93% 78%, 15% 77%); */
    clip-path: polygon(0 theme(spacing.14),
        100% theme(spacing.12),
        calc(100% - theme(spacing.10)) calc(100% - theme(spacing.10)),
        theme(spacing.8) calc(100% - theme(spacing.12)));
    -webkit-clip-path: polygon(0 theme(spacing.14),
        100% theme(spacing.12),
        calc(100% - theme(spacing.10)) calc(100% - theme(spacing.10)),
        theme(spacing.8) calc(100% - theme(spacing.12)));
  }

  .clip-image {
    -webkit-clip-path: polygon(0 2%, 100% 0, 87% 68%, 16% 68%);
    clip-path: polygon(0 2%, 100% 0, 87% 68%, 16% 68%);
  }
}

.maskFounder {
  mask-image: url("./assets/about-us/frame.png");
  object-fit: cover;
  width: 500px;
  /* height:500px; */
  mask-repeat: no-repeat;
}

/* 
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  background: #f40076;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #f40076;
} */

/* 
7% 15%,
      100% 15%,
      93% calc(100% - theme(spacing.10)),
      15% calc(100% - theme(spacing.12)) */

.div1 {
  width: 2em;
  height: 2em;
  background-color: transparent;
  z-index: 80;
  bottom: 25px;
  position: fixed;
  border-width: 0 0.25em 0.25em 0;
  border-style: solid;
  border-color: #f40076;
  animation: scrolldown 1.2s ease-in-out infinite 0.15s;
  left: 50%;
  transform: translateY(0%) rotate(45deg);
  opacity: 0;
}

.div2 {
  width: 2em;
  height: 2em;
  background-color: transparent;
  z-index: 80;
  bottom: 40px;
  position: fixed;
  border-width: 0 0.25em 0.25em 0;
  border-style: solid;
  border-color: #f40076;
  animation: scrolldown 1.2s ease-in-out infinite;
  left: 50%;
  transform: translateY(0%) rotate(45deg);
  opacity: 0;
}

@keyframes scrolldown {
  0% {
    transform: translateY(20%) rotate(45deg);
    opacity: 0.7;
  }

  50% {
    transform: translateY(0%) rotate(45deg);
    opacity: 0.2;
  }

  100% {
    transform: translateY(20%) rotate(45deg);
    opacity: 0.7;
  }
}